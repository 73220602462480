import styled from "@emotion/styled"
import theme from "./theme"

export const LargeContainer = styled.div`
	max-width: ${theme.sizes.large}px;
	margin:auto;
`

export const MediumContainer = styled.div`
	max-width: ${theme.sizes.medium}px;
	margin:auto;
`

export const SmallContainer = styled.div`
	max-width: ${theme.sizes.small}px;
	margin:auto;
`

export const PaddingContainer = styled.div`
	padding-left: ${theme.sizes.padding}px;
	padding-right: ${theme.sizes.padding}px;
	@media(max-width:${theme.sizes.mobile}px) {
		padding-left: ${theme.sizes.mobilePadding}px;
		padding-right: ${theme.sizes.mobilePadding}px;
	}
`

import { css } from "@emotion/react"
import MaaxBold from "./fonts/Maax-Bold.woff2"

const globalFonts = css`
	@font-face {
		font-family: "Maax";
		src: url('./fonts/Maax-Bold.woff2') format('woff2')
	}
	@font-face {
  font-family: "Founders";
  src: url("./fonts/FoundersGrotesk-Regular.woff2") format('woff2')
}
`

export default globalFonts
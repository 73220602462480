import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import styled from "@emotion/styled"
import { PaddingContainer, LargeContainer } from "../styled"

const Header = ({ siteTitle }) => (
  <header>
		<PaddingContainer>
			<HeaderContainer>
				<div className="left">
					<Link to="/">
						<StaticImage
							src="../images/kosmique-logo.png"
							width={150}
							quality={95}
							formats={["auto", "webp", "avif"]}
							alt="A Gatsby astronaut"
							placeholder="white"
						/>
						</Link>
				</div>
				<div className="right">
					<NavLink className="nav-link" to="/#our-story">ABOUT</NavLink>
					<NavLink className="nav-link" to="/#footer">GET IN TOUCH</NavLink>
				</div>
			</HeaderContainer>
		</PaddingContainer>
  </header>
)

export default Header

const HeaderContainer = styled(LargeContainer)`
	display:flex;
	justify-content: space-between;
	padding-top:30px;
	padding-bottom:30px;
	a {
		color: black;
		text-decoration: none;
		margin-left:40px;
		@media(max-width: 560px) {
			margin-left: 0;
			margin-right: 40px;
		}
	}
	@media(max-width: 560px) {
		flex-direction:column;
		.right {
			margin-top:20px;
			a {

			}
		}
	}
`

const NavLink = styled(Link)`
	/* color: black;
	text-decoration: none;
	margin-left:40px;
	@media(max-width: 560px) {
		margin-left: 0;
		margin-right: 40px;
	} */
`
const theme = {
	colors: {
		background:'#ffffff',
		blob: 'white',
		text: "#2E6194",
		border: "#BFBFBF",
		buttonBackground:"#666",
		linkText: "#000",
		error: "orange",
		pink: '#FD8FBE',
		white: 'white',
		purple: "#A17EF6",
		lightGrey: "#F2F2F3",
		grey: "#BFBFBF",
		darkGrey: "#979797",
		black: "#000",
	},
	sizes: {
		headerHeight: 70,
		footerHeight: 70,
		outerMargin: 30,
		innerMargin: 10,
		cardPadding: 10,
		padding: 50,
		mobilePadding: 20,
		mobile: 400,
		small: 980,
		medium: 1200,
		large: 1700
	},
	fonts: {
			
	}
}

export default theme